<template>
  <div class="module-category-series"
       v-if="topicDetail"
       :style="{backgroundColor: '#'+ topicDetail.background_color_hex, backgroundImage: 'url('+topicDetail.background_image_url+')'}">
    <div class="topicDetail-box">
      <div class="title">{{ topicDetail.name }}</div>
      <div class="desc">
        <span v-for="item in topicDetail.description" :key="item">{{ item }}</span>
      </div>
    </div>
    <div class="container pageContent">
      <div class="section-series">
        <div class="series-list">
          <div class="series-item"
               v-for="item in seriesList"
               :key="item.id"
               :class="currentSeriesId === item.id?'series-item-active':''"
               @click="handleChangeSeries(item)"
          >
            <div class="item-top" :style="{backgroundImage:  'url('+ item.background_image_url+')'}">
              <div> {{ item.name }}</div>
              <div> {{ item.summary }}</div>
            </div>
            <div class="item-bottom">
              <div class="desc-item" v-for="desc in item.description" :key="desc">
                <span class="desc-point" :style="{background:`#${item.color_hex}`}">
                </span>
                <div class="desc-text">{{ desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-spu">
        <div class="spu-tabs">
          <div class="tab-header">
            <div class="tab-header-list">
              <div class="tab-header-item" v-for="(spu, index) in spuList"
                   :class="spu.id === activeSpuId?'tab-header-item-active':''"
                   :key="spu.id"
                   :id="`spuId${spu.id}`"
                   @click="handleChangeSpu(spu, index)"
              >{{ spu.name }}
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div class="spec-list">
              <div class="spec-item" v-for="(item, specIndex) in currentSpu.specs" :key="item.id">
                <div class="item-title">{{ item.name }}</div>
                <!--                <div class="item-values" v-for="value in item.values" :key="value">-->
                <!--                  <div class="item-value">{{value}}</div>-->
                <!--                </div>-->
                <template
                        v-for="value in item.values"
                >
                <span class="item-value item-value-disabled"
                      :key="value"
                      v-if="specIndex === currentSpu.specs.length - 1 && !canUseLastLevel.includes(value)"
                >{{ value }}</span>
                  <span v-else
                        class="item-value"
                        :key="value"
                        :class="[activeAttributeArr.includes(value)?'item-value-active':'']"
                        @click="handleChooseAttribute(item.name, value, specIndex)"
                  >
                 {{ value }}
                    <i v-if="canUseStockStatus[value] ===STOCK_STATUS.STOCK_SHORT"
                       class="stock-status stock-status-short">紧张</i>
                    <i v-if="canUseStockStatus[value] ===STOCK_STATUS.SOLD_OUT"
                       class="stock-status stock-status-out">售罄</i>
                </span>

                </template>
              </div>

            </div>
            <div class="description-list">
              <div class="desc-item" v-for="(item, key) in currentSpu.desc" :key="key">
                <div class="item-title" v-if="item.type === SPU_DESCRIPTION_TYPE.LIGHTSPOT">班级亮点</div>
                <div class="item-title" v-else-if="item.type === SPU_DESCRIPTION_TYPE.SUITABLE_FOR_PEOPLE">适合学员</div>
                <div class="item-title" v-else-if="item.type === SPU_DESCRIPTION_TYPE.PURCHASE_GUIDE">购课说明</div>
                <div class="item-title" v-else>未定义的类型</div>
                <div class="item-container">
                  <div class="item-value" v-for="(img, index) in item.values" :key="index+img">
                    <img v-lazy="img" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="handle-box">
              <div class="button button-detail" @click="particularsBtn">了解详情</div>
              <div class="button button-service" @click="handleGoDetail">定制专属课表 <span
                      class="button-highlight">立即点击咨询</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-teacher" v-if="isShowTeachers">
        <div class="section-teacher-title">权威师资 优质中教</div>
        <teacher-list :category-id="categoryId"></teacher-list>
      </div>
      <div class="section-ads" v-if="adsList">
        <a class="ads-item" v-for="ad in adsList" :key="ad.image_url" :href="ad.link_url">
          <img v-lazy="ad.image_url" alt="">
        </a>
      </div>
      <div class="qr-code" v-show="isShowQrcode">
          <div class="close-btn iconfont icon-papa-close-small" @click="delQrcodeBox"></div>
          <div class="title-zixun">立即咨询</div>
          <img class="qr-image" :src="qrImage" alt="">
          <div class="tip">微信扫码添加小助手，咨询课程</div>
      </div>
    </div>
  </div>
</template>

<script>
  import CategoryIndex from './CategorySeries'

  export default CategoryIndex
</script>

<style lang="less" scoped>
.qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title-zixun {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }
  .module-category-series {
    min-height: 100vh;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 400px;
    padding-bottom: 60px;
    .topicDetail-box {
      height: 300px;
      margin-bottom: 12px;
      .desc {
        span {
          margin-right: 45px;
        }
      }
    }
     .title {
      width: 100%;
      text-align: center;
      font-size: 54px;
      font-weight: bold;
      color: #fff;
      padding-top: 106px;
      margin-bottom: 20px;
      font-family: SourceHanSansCN-Bold;
    }

     .desc {
      width: 100%;
      text-align: center;
      font-size: 22px;
      color: #fff;
      margin-bottom: 86px;
      span{
        margin-right: 54px;
      }
    }

    & > .container {
      box-sizing: border-box;
      padding: 40px 30px;
      background: #fff;
      border-radius: 28px;

      & > .section-series {
        margin-bottom: 60px;

        .series-list {
          display: flex;
          //justify-content: space-between;
          .series-item {
            width: 270px;
            height: 226px;
            border-radius: 20px;
            background: #F5F5F5;
            margin-right: 20px;
            flex-shrink: 0;
            .item-top {
              box-sizing: border-box;
              padding: 22px 20px;
              width: 100%;
              height: 100px;
              border-radius: 20px;
              text-align: left;
              background-repeat: no-repeat;
              background-size: 100%;
              div{
                margin-left: 60px;
                font-size: 20px;
                color: #fff;
              }
            }

            .item-bottom {
              box-sizing: border-box;
              padding: 17px 27px 18px;
              height: 126px;
              text-align: left;
              font-size: 16px;
              color: rgba(0,0,0,0.9);
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              .desc-item {
                display: flex;
              }
              .desc-point {
                display: inline-block;
                margin-right: 8px;
                width: 6px;
                height: 6px;
                border-radius: 2px;
                margin-top: 12px;
                flex-shrink: 0;
              }
              .desc-text {
                display: inline;
                line-height: 30px;
              }
            }
          }

          .series-item-active {
            background: #fff;
            box-shadow: 0px 2px 16px 0px rgba(61, 61, 61, 0.1000);
          }
        }
      }

      & > .section-spu {
        .spu-tabs {
          .tab-header {
            width: 100%;
            height: 66px;
            // border-radius: 0 0 25px 0;
            overflow: hidden;
          }

          .tab-header::-webkit-scrollbar {
            display: none;
          }

          .tab-header-list {
            // position: relative;
            display: flex;
            overflow-x: auto;
            height: 68px;

            .tab-header-item {
              box-sizing: border-box;
              width: 240px;
              height: 66px;
              border-radius: 12px 12px 0 0;
              flex: 0 0 auto;
              background: #fff;
              color: rgba(0,0,0,0.7);
              font-weight: 500;
              font-size: 16px;
              line-height: 66px;
              text-align: center;
              cursor: pointer;
              border: 1px solid transparent;
              border-bottom: 1px solid #E6e6e6;
            }

            .tab-header-item-active {
              border: 1px solid #e6e6e6;
              border-bottom: 0;
              color: #29d087;
              font-weight: bold;
              font-size: 20px;
            }

          }
          .tab-header-list::-webkit-scrollbar {
              display: none;
            }
          .tab-content {
            box-sizing: border-box;
            padding: 40px;
            width: 100%;
            border: 1px solid #E6E6E6;
            margin-top: -1px;
            border-radius: 0 0 20px 20px;

            .spec-list {
              .spec-item {
                display: flex;
                margin-bottom: 40px;
                align-items: center;

                .item-title {
                  font-size: 16px;
                  color: #000;
                  font-weight: bold;
                  margin-right: 20px;
                }

                .item-value {
                  background: #f5f5f5;
                  border-radius: 30px;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.7);
                  text-align: center;
                  padding: 8px 28px;
                  border: 1px solid transparent;
                  margin-right: 20px;
                  cursor: pointer;
                  position: relative;
                }

                .item-value:hover {
                  background: #FFFFFF;
                  box-shadow: 0px 2px 10px 0px rgba(128, 128, 128, 0.1000);
                  border-radius: 30px 30px 30px 30px;
                  border: 1px solid #EBEBEB;
                }

                .item-value-active {
                  background: #EBFBF4;
                  color: #29d087;
                }

                .item-value-disabled {
                  border: 2px dashed #EBEBEB;
                  cursor: not-allowed;
                  padding: 7px 27px;
                }

                .item-value-disabled:hover {
                  box-shadow: none;
                  background: #f5f5f5;
                  border: 2px dashed #EBEBEB;
                  cursor: not-allowed;

                }


                .stock-status {
                  position: absolute;
                  top: -10px;
                  right: 0;
                  padding: 2px;
                  width: 26px;
                  height: 14px;
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 14px;
                  transform: scale(0.8);
                  font-style: normal;
                  text-align: center;
                  border-radius: 2px;
                }

                .stock-status-short::before {
                  position: absolute;
                  left: -2px;
                  top: 6px;
                  content: '';
                  width: 6px;
                  height: 6px;
                  transform: rotate(45deg);
                  background: #F67942;
                  border-radius: 2px;
                }

                .stock-status-short {
                  background: linear-gradient(-90deg, #F64A42 0%, #F67942 100%);
                }

                .stock-status-out {
                  background: #999999;
                }

                .stock-status-out::before {
                  position: absolute;
                  left: -2px;
                  top: 6px;
                  content: '';
                  width: 6px;
                  height: 6px;
                  transform: rotate(45deg);
                  background: #999999;
                  border-radius: 2px;
                }
              }
            }

            .description-list {
              .desc-item {
                margin-bottom: 40px;

                .item-title {
                  font-size: 16px;
                  color: #000;
                  font-weight: bold;
                  margin-right: 20px;
                  text-align: left;
                  margin-bottom: 18px;
                }

                .item-container {
                  display: flex;

                  .item-value {
                    width: 160px;
                    height: 90px;
                    margin-right: 20px;
                    background: #FFFFFF;
                    border-radius: 8px 8px 8px 8px;
                    opacity: 1;
                    border: 1px solid #EBEBEB;
                    font-size: 0;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

              }
            }

            .handle-box {
              display: flex;
              justify-content: center;

              .button {
                border-radius: 60px;
                height: 58px;
                text-align: center;
                line-height: 58px;
                font-size: 16px;
                user-select: none;
                cursor: pointer;
              }

              .button-detail {
                width: 144px;
                height: 54px;
                border: 2px solid #29d087;
                color: #29d087;
                margin-right: 26px;
              }

              .button-service {
                width: 256px;
                background: #29d087;
                color: #fff;

              }

              .button-highlight {
                color: #FFF441;
                font-size: 18px;
              }
            }
          }

        }
      }

      & > .section-teacher {
        height: 770px;
        .section-teacher-title {
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          margin: 50px 0;
        }
      }

      & > .section-ads {
        .ads-item {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 60px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
</style>
