import * as Api from '@/api/index/index'
import { switchCategory } from '@/utils/utils'
import { SPU_DESCRIPTION_TYPE, STOCK_STATUS } from '@/enums/API'
import _ from 'loadsh'
import TeacherList from '@/components/Teacher/Teacher.vue'

export default {
    name: 'CategorySeries',
    data() {
        return {
            categoryId: 1,
            topicDetail: null,
            seriesList: [],
            currentSeriesId: '',
            spuList: [],
            activeSpuId: '',
            currentSpu: {},
            activeSkuId: '',
            currentSkuList: [],
            activeAttributeArr: [],
            canUseLastLevel: [],
            canUseStockStatus: {},
            adsList: [],
            imgDomain: '',
            isShowTeachers: false,
            SPU_DESCRIPTION_TYPE,
            STOCK_STATUS,
            isShowQrcode: false,
            qrImage: '',
            categoryData: [],
            category: 'gre',
        }
    },
    components: {
        TeacherList
    },
    // props: ['seriesId'],
    mounted() {
        let path = this.$route.path
        this.category = path.split('/')[1]
        this.categoryId = switchCategory(this.$route.path)
        this.getTopic()
        this.currentSeriesId = parseInt(this.$route.query.seriesId)

        this.imgDomain = process.env.VUE_APP_IMAGE_DOMAIN
    },
    methods: {
        // pathFn() {
        //     this.$route.push({
        //         path: '/series',
        //         query: {
        //             seriesId: this.categoryId
        //         }
        //     })
        // },
        getTopic() {
            Api.categoryTopicApi(this.categoryId, res => {
                this.topicDetail = res
                this.getSeries()
            })
        },
        getSeries() {
            Api.topicSeriesApi(this.categoryId, res => {
                this.seriesList = res
                if (!this.currentSeriesId) {
                    this.currentSeriesId = res[0].id
                }
                this.getSpus()
                this.getSeriesAds()

            })
        },

        getSpus() {
            const params = {
                category_id: this.categoryId,
                series_id: this.currentSeriesId
            }
            Api.seriesSpuApi(params, res => {
                const data = this.formatSpuList(res)
                this.spuList = data
                this.handleChangeSpu(data[0])
            })
        },
        handleChangeSeries(series) {
            this.currentSeriesId = series.id
            this.isShowTeachers = series.is_show_teachers
            this.currentSpu = {}
            this.getSeriesAds()
            this.getSpus()
        },
        handleChangeSpu(spu, index) {
            console.log(spu)
            this.activeSpuId = spu.id
            this.activeSkuId = spu.default_sku_id
            this.currentSpu = spu
            this.currentSkuList = spu.skus
            this.findSku()
            if (index < 4 && index !== 0) return
            setTimeout(() => {
                const targetDom = document.querySelector(`#spuId${spu.id}`)
                console.log(targetDom.offsetLeft)
                const boxDom = document.querySelector('.tab-header-list')
                boxDom.scrollTo({ top: 0, left: (index - 3) * 180, behavior: 'smooth' })
            })
        },

        formatSpuList(list) {
            list.forEach(item => {
                let _desc = {}
                item.descriptions.forEach(ele => {
                    if (_desc[ele.type]) {
                        _desc[ele.type].push(ele.image_url)
                    } else {
                        _desc[ele.type] = [ele.image_url]
                    }
                })
                let descArr = []
                let descKeyArr = Object.keys(_desc)
                descKeyArr.forEach(key => {
                    descArr.push({
                        type: parseInt(key),
                        values: _desc[key]
                    })
                })
                item.desc = descArr
            })
            return list
        },
        handleChooseAttribute(key, value, index) {
            let targetArr = this.activeAttributeArr
            targetArr.splice(index, 1, value)
            let targetPath = targetArr.join(';')
            let count = 0
            this.currentSkuList.forEach(sku => {
                if (sku.path.includes(targetPath)) {

                    this.activeSkuId = sku.id
                } else {
                    count++
                }
            })
            this.specStr()
            if (count === this.currentSkuList.length) {
                let target = this.activeAttributeArr.splice(this.activeAttributeArr.length - 1, 1)
                this.canUseLastLevel.forEach((item, index) => {
                    if (item === target[0]) {
                        this.canUseLastLevel.splice(index, 1)
                    }
                })
            }
        },
        findSku() {
            this.currentSkuList.forEach((sku) => {
                if (sku.id === parseInt(this.activeSkuId)) {
                    this.activeAttributeArr = sku.path.split(';')
                    this.specStr()
                }
            })
        },
        specStr() {
            this.canUseLastLevel = []
            this.canUseStockStatus = {}
            let activeAttributeArr = _.cloneDeep(this.activeAttributeArr)
            activeAttributeArr.splice(activeAttributeArr.length - 1, 1)
            let activeAttributeStr = activeAttributeArr.join(';')
            this.currentSkuList.forEach(sku => {
                if (sku.path.includes(activeAttributeStr)) {
                    let arr = sku.path.split(';')
                    this.canUseLastLevel.push(arr[arr.length - 1])
                    this.canUseStockStatus[arr[arr.length - 1]] = sku.stock_status
                }
            })
        },
        getSeriesAds() {
            Api.seriesAds(this.currentSeriesId, (res) => {
                this.adsList = res
            })
        },
        handleGoDetail() {

            this.categoryData = this.$store.state.common.categoryTypes
            this.categoryData.forEach(item => {
                if (item.abbr?.toUpperCase() === this.category.toUpperCase()) {
                    this.isShowQrcode = true
                    this.qrImage = item.qrcode_image_url
                }
            })
        },
        particularsBtn() {
            this.$router.push({
                name: 'Course',
                params: {
                    spuId: this.activeSpuId,
                    skuId: this.activeSkuId
                }
            })
        },
        delQrcodeBox() {
            this.isShowQrcode = false
        }
    },

}
